import Vue from 'vue';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-boost';
import DoublePaneLayout from '@/components/Admin/DoublePaneLayout/DoublePaneLayout';
import Button from '@/components/Admin/Button/Button';
import apollo from '@/services/apollo';
import { sortAlphabetically } from '@/services/utils';

import './Offerings.css';

const initialFormData = {
  description: '',
  enabled: true,
  name: '',
  price: '',
};

export interface OfferingModel {
  description: string;
  enabled: boolean;
  id?: string;
  name: string;
  price: string;
}

const OfferingSidebarItem = (context: any) => {
  const offering = context.props.item as OfferingModel;

  const handleDeleteOffering = (event: Event, ) => {
    event.preventDefault()

    // TODO: Use a modal
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm('Are you sure you want to delete this slot');

    if (!confirmed || !offering.id) return;

    const mutation = gql`
    mutation deleteOffering($id: String!) {
      deleteOffering(id: $id) {
        ok
      }
    }
  `;

    apollo.mutate({
      mutation,
      variables: { id: offering.id },
    })
      .then(() => {
        (event.target as HTMLAnchorElement).parentElement?.classList.add('hide');
      })
      .catch((err) => {
        console.log('Error deleting offering => ', err);
      });
  }

  return (
    <li class="sidebar-item offering-item">
      <router-link
        class="offering-item__link"
        onClick={(event: Event) => event.preventDefault()}
        to={`/admin/settings/offerings/${offering.id}`}
      >
        <span class="offering-item__link__title">
          {offering.name} - {parseInt(offering.price).toLocaleString()}
        </span>

      </router-link>

      <em class={`offering-item__link__status ${offering.enabled ? "enabled" : "disabled"}`}>

      </em>

      <a class={`offering-item__delete`} onClick={handleDeleteOffering}>
        <img src={require('@/components/Icons/delete.png')} alt="Delete icon" />
      </a>
    </li >
  );
};

export interface OfferingsData {
  currentOffering: OfferingModel | null;
  formData: OfferingModel;
  formError: string;
  loading: boolean;
  offeringId: string;
  offerings: OfferingModel[];
  submissionInProgress: boolean;
}

const Offerings = Vue.extend({
  data(): OfferingsData {
    return {
      currentOffering: null,
      formData: { ...initialFormData },
      formError: '',
      loading: true,
      offeringId: '',
      offerings: [],
      submissionInProgress: false,
    };
  },

  methods: {
    checkIfIsEdit() {
      this.offeringId = this.$route.params.offeringId || '';

      if (!this.offeringId) {
        this.formData = { ...initialFormData };
        return;
      }

      const offering = this.offerings.find(item => item.id === this.offeringId) || null;
      this.currentOffering = offering;

      if (offering) {
        this.formData = {
          ...offering,
        };
      }
    },

    handleCreateOffering(event: Event) {
      event.preventDefault();

      this.submissionInProgress = true;
      this.formError = '';

      const { offeringId } = this;

      const createOfferingMutation = gql`
        mutation createOffering(
          $description: String!
          $name: String!
          $enabled: Boolean!
          $price: Int!
        ) {
          createOffering(
            description: $description
            enabled: $enabled
            name: $name
            price: $price
          ) {
            id
          }
        }
      `;

      const updateOfferingMutation = gql`
        mutation updateOffering(
          $description: String!
          $enabled: Boolean!
          $id: String!
          $name: String!
          $price: Int!
        ) {
          updateOffering(
            description: $description
            enabled: $enabled
            id: $id
            name: $name
            price: $price
          ) {
            id
          }
        }
      `;

      apollo.mutate({
        mutation: offeringId ? updateOfferingMutation : createOfferingMutation,
        variables: {
          ...this.formData,
          price: parseInt(this.formData.price, 10),
        },
      })
        .then(({ data }) => {
          if (offeringId) return;

          const item = {
            ...this.formData,
            enabled: true,
            id: data?.createOffering?.id || '',
          };

          this.offerings = sortAlphabetically([...this.offerings, item], 'name');
        })
        .catch((err: ApolloError) => {
          this.formError = err.graphQLErrors.map(({ message }) => message).join(' ');
        })
        .finally(() => {
          this.submissionInProgress = false;
        });
    },

    loadOfferings() {
      const query = gql`
        {
          getOfferings(all: true){
            enabled
            description
            id
            name
            price
          }
        }
      `;

      apollo.query({ query })
        .then(({ data }) => {
          this.offerings = data?.getOfferings || [];

          this.checkIfIsEdit();
        })
        .catch((err) => {
          console.log('Error loading available services', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.loadOfferings();
  },

  name: 'offerings',

  render() {
    return (
      <DoublePaneLayout
        filterHandler={null}
        renderSidebarItem={OfferingSidebarItem}
        search={null}
        sidebarLoading={this.loading}
        sidebarItems={this.offerings}
        sidebarLink={null}
        sortHandler={null}
      >
        <div class="new-booking-slot">
          <header class="dashboard-header">
            <h1 class="dashboard-header__title">New Package</h1>
          </header>

          <form class="dashboard-content" onSubmit={this.handleCreateOffering}>

            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" name="name" v-model={this.formData.name} id="name" required />
            </div>

            <div class="form-group">
              <label for="description">Description</label>
              <textarea type="text" cols="30" rows="4" name="description" v-model={this.formData.description} id="description" required />
            </div>

            <div class="form-group">
              <label for="price">Price</label>
              <input type="number" min="0" name="price" v-model={this.formData.price} id="price" required />
            </div>

            <div class="form-group checkbox">
              <input type="checkbox" name="enabled" id="enabled" v-model={this.formData.enabled} />
              <label for="price">Enabled</label>
            </div>

            {
              this.formError && (
                <div class="form-group">
                  <div class="form-error">{this.formError}</div>
                </div>
              )
            }

            <div class="form-group">
              <Button loading={this.submissionInProgress} primary type='submit'>
                {
                  this.currentOffering
                    ? 'Update '
                    : 'Create '
                }
                Package
              </Button>
            </div>
          </form>
        </div>
      </DoublePaneLayout>
    );
  },

  watch: {
    $route: 'checkIfIsEdit',
  },
});


export default Offerings;
